.webform-standalone-container {
  .webform-login-wrapper,
  .webform-complete-wrapper {
    margin-top: 150px;
  }

  .webform-login-card {
    max-width: 600px;
    width: 90%;
    .ant-card-head-title {
      white-space: pre-line;
    }
  }

  .webform {
    padding: 0;
    form {
      min-height: 700px;
    }
  }

  .arf-mobile-header-wrapper > h1 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  // css for webform
  @media only screen and (max-width: 330px) {
    .arf-mobile-footer-container {
      .ant-col:first-child {
        .ant-space-item:nth-child(3) {
          display: none;
        }
      }
    }
  }
}
