.App {
  .container {
    padding: 24px 40px;
  }

  .ant-layout {
    background-color: #fff;

    .ant-layout-header {
      background-color: #fafafa;

      .header-menu {
        text-align: right;

        .menu-outlined {
          color: #45add9;
        }
      }
    }

    .ant-layout-header.webform-standalone {
      padding: 0 20px;
    }

    .ant-layout-content {
      // padding: 20px 40px;
      min-height: 54.2rem;

      .ant-result {
        padding: 25vh 24px;

        &.loading {
          padding: 10vh 24px;

          .ant-result-icon > .anticon {
            font-size: 40px;
          }
        }
      }
    }

    .ant-layout-footer {
      background-color: #45add9;
      color: #fff;
      font-size: 14px;

      /* FIXME: Revert once footer is changed */
      // padding: 20px 0px 0px 0px;
      padding: 0px;

      .footer-start,
      .footer-end {
        padding: 0 40px;
      }

      h2 {
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
      }

      .footer-end {
        /* FIXME: Revert once footer is changed */
        // margin-top: 20px;
        background-color: #0288d1b7;
        color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;

        a {
          color: #fff;
          text-decoration: none;
        }

        .start {
          a {
            margin-right: 10px;
          }
        }

        .middle {
          text-align: center;
        }

        .end {
          text-align: right;

          a {
            margin-left: 10px;
            &:not(:first-child) {
              border-left: 1px solid #ddd;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.ant-drawer {
  z-index: 1002 !important;
}

.menu-drawer-container {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-close {
    color: #45add9;
  }

  .ant-menu-item-group {
    background: #fff;
    color: #45add9;
  }

  .auth-button-wrapper {
    padding: 24px;
    background: rgba(69, 173, 217, 0.15);

    .ant-btn {
      color: #45add9;
      border: none;
    }

    .anticon {
      border: 1px solid #45add9;
      border-radius: 50%;
      padding: 0.5px;
    }
  }

  .ant-drawer-body {
    .ant-menu.ant-menu-root {
      .ant-menu-item {
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 0px;
        padding-bottom: 5px;
      }

      .ant-menu-item-group {
        .ant-menu-item-group-title {
          padding-left: 24px;
          border-bottom: 1px solid #f0f0f0;
        }

        ul.ant-menu-item-group-list {
          li.ant-menu-item a {
            padding-left: 24px;
          }
        }
      }
    }
  }
}

.header-logo {
  a {
    color: #000000;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: #000000;
    }

    .web-title {
      font-size: 18px;
    }
  }
}

.webform-progress-bar {
  padding: 20px;
  width: 100%;
  background: #fafafa;
  transition: background-color linear 0.2s;
}

.ant-affix {
  .webform-progress-bar {
    background: #ffffff;
  }
}

.webform {
  .blue-header {
    background-color: #0288d133;
    height: 300px;
    width: 100%;
    position: absolute;
    transition: background-color linear 0.2s;
  }

  .blue-header.to-white {
    background-color: #ffffff;
  }

  h1 {
    font-weight: bold;
    color: #0288d1;
  }

  .form-container {
    margin-top: 50px;
    margin-bottom: 50px;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;

    .arf-sticky {
      top: 62px;
    }

    .arf-form-header.arf-sticky {
      border-top: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
    }

    .arf-sidebar.arf-sticky {
      top: 121px;
    }
  }
}

.popup-notification {
  span.anticon.anticon-info-circle {
    display: none;
  }

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      display: none;
    }
  }

  .ant-modal-body {
    padding: 32px;

    .ant-avatar {
      margin-bottom: 20px;
      background: #45add94d;
      color: #45add9;

      &.submit {
        background: #01770e4d;
        color: #01770e;
      }

      &.delete {
        background: #9f00314d;
        color: #9f0031;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
    }

    p {
      width: 70%;
    }
  }

  .ant-modal-footer {
    border-top: none;
    float: none;
    width: 100%;
    display: flex;
    justify-content: center;

    .ant-btn,
    .ant-btn-primary {
      margin-bottom: 12px;
      font-size: 13px;
      border-radius: 4px;
      font-weight: 600;
      background: #45add94d;
      color: #45add9;
      border: 1px solid #45add9;

      &.submit {
        background: #01770e4d;
        color: #01770e;
        border: 1px solid #01770e;
      }

      &.delete {
        background: #9f00314d;
        color: #9f0031;
        border: 1px solid #9e0030;
      }
    }

    .ant-btn:disabled,
    .ant-btn-primary:disabled {
      &.delete {
        background: #707070;
        color: #ffff;
        border: 1px solid #707070;
      }
    }
  }
}

.ant-popover-inner-content {
  // padding: 0 !important;
  min-width: 300px;
}

.advance-search-container {
  margin-bottom: 24px;

  .advance-search-collapse {
    margin-bottom: 10px;

    .advance-search-panel {
      .ant-collapse-header {
        padding: 0 !important;
      }
    }

    .ant-collapse-content-box {
      background-color: #fff;
      border-radius: 4px;
      padding: 24px !important;

      .search-question-option-wrapper {
        width: 100%;

        .search-question-select {
          width: 100%;
        }
      }

      .ant-radio-wrapper,
      .ant-checkbox-wrapper {
        display: block;
        margin-left: 0;
      }

      .ant-checkbox-wrapper {
        .ant-checkbox-inner {
          display: inline-block;
        }
        .ant-checkbox-checked::after {
          top: -2px;
        }
      }
    }
  }
}

.attachment-badge {
  float: right;
  display: inline-block;
}

.dotted-tag {
  background: transparent;
  border-style: dashed;
}

// to solve antd overflow gutter issue
.ant-row .row-gutter {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.flexible-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .flexible-columns {
    flex: 1 1 0;
    width: 0;
  }
}
