.main-container {
  h1 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .ant-affix {
    .filter-wrapper {
      background: #fff;
      box-shadow: 0px 1px 9px #b9b9b9;

      .container {
        padding: 12px 40px 4px 40px;
      }
    }
  }

  .filter-wrapper {
    background: #f3f3f3;
    transition: background-color 0.2s;

    .container {
      transition: padding 0.2s;
    }

    .filter-select {
      width: 200px;
    }

    .filter-select,
    .remove-filter-button {
      z-index: 1;
    }

    .advance-search-container {
      margin-top: 24px;
    }
  }

  .data-container {
    min-height: 585px;

    .map-wrapper {
      background: #fff;
      max-height: 600px;
      overflow: hidden;

      .container {
        margin: 0px;
        padding: 0px;
      }

      .map-loading {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;

        .ant-spin-spinning {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: auto;
        }
      }
    }

    .table-wrapper {
      min-height: 585px;
      background: #7070701a;

      .chart-loading {
        .ant-spin-spinning {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: auto;
        }
      }

      .chart-container {
        height: 468px;
        max-height: 468px;
        margin: 24px 40px;
        padding: 0px !important;
        display: block !important;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #fff;
        border: 3px solid #ededed;

        &.odf {
          overflow-y: hidden;
          margin: 0px 0px;
          height: 413px;
          max-height: 413px;
          min-height: 413px;

          .odf-chart {
            height: 100%;
          }
        }

        .data-info {
          border-top: 1px solid #eee;
          border-bottom: 3px solid #0288d1;
          padding: 10px;

          .info.title {
            color: #0288d1 !important;
            font-size: 14px;
          }
        }

        .jmp-chart {
          padding: 20px;
        }
        .jmp-chart-container {
          min-height: 300px;
        }
      }

      .main-table-container {
        border: 3px solid #ededed;
      }

      .data-info {
        .info {
          font-size: 12px;

          &.title {
            color: #00989f !important;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }

      .ant-table.ant-table-small {
        font-size: 12px;
      }

      .ant-table-filter-trigger {
        .anticon {
          color: #fff;
        }
      }

      tr.ant-table-row.edited {
        background-color: #fefebe;
      }

      tr.ant-table-row.edited {
        &:hover {
          td {
            background-color: #ffeb3b;
          }
        }
      }

      .ant-table-thead {
        th {
          font-size: 14px;
        }
      }
    }

    .ant-table-thead > tr > th {
      background: #fff !important;
      border-color: #fafafa;
      border-bottom: 3px solid #0288d1;
      color: #0288d1;
      font-weight: bold;
      padding: 10px;

      &:first-child {
        padding-left: 20px;
      }

      &.ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }

    tr.ant-table-row.edited {
      background-color: #fefebe;
    }

    tr.ant-table-row.edited {
      &:hover {
        td {
          background-color: #ffeb3b;
        }
      }
    }
  }

  .collapse-wrapper,
  .visual-card-wrapper {
    background: #45add90f 0% 0% no-repeat padding-box;
    min-height: 300px;
    border: 1px solid #fff;

    &.splited {
      .ant-card-head-title {
        font-size: 14px !important;
      }
    }

    .ant-card-head-title {
      text-align: center;
    }

    .chart-loading {
      .ant-spin-spinning {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
      }
    }

    .ant-collapse {
      margin-bottom: 18px;
    }

    .ant-collapse-header,
    .ant-card-head {
      background: #00989f;
      color: #fff !important;
      font-weight: bold;
      font-size: 18px;
    }

    .ant-collapse-borderless {
      .ant-collapse-item {
        border-bottom: none !important;
        background-color: #e3edec;
        border-radius: 5px;
      }
    }
  }

  .leaflet-container {
    height: 585px;
    width: 100%;
    z-index: 0;
  }

  .map-buttons {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;
  }

  .legends-wrapper {
    position: absolute;
    bottom: 17px;
    left: 0px;
    width: 100%;
    padding: 12px 20px 12px;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;

    h4 {
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }

    .ant-slider.shape-legend-slider {
      .ant-slider-rail,
      .ant-slider-track {
        height: 8px;
      }

      .ant-slider-track {
        background-color: #67bea1;
      }

      .ant-slider-handle {
        width: 18px;
        height: 18px;
        border: solid 3px #67bea1;
      }

      // &.sanitation {
      //   .ant-slider-track {
      //     background-image: linear-gradient(
      //       to right,
      //       #fff,
      //       #f1ac2a,
      //       #fbd256,
      //       #fdf177,
      //       #79be7d,
      //       #368541
      //     );
      //   }
      // }
    }

    .slider-number-wrapper {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 600;
    }

    .extra-title {
      display: inline-block;

      .legend-icon {
        height: 10px;
        width: 10px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 5px;
      }
    }

    .legends {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .legend {
        flex: 1 1 0;
        width: 0;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
        padding: 5px 10px;
        color: #226799;
        font-size: 12px;
        border-right: solid 2px #daeaf1;
        line-height: 10px;
      }

      .legend.legend-selected {
        border: solid #036799 2px;
        border-right: solid 2px #036799;
        padding: 3px 8px;
        color: #fff;
      }
    }
  }

  .marker-dropdown-container {
    position: absolute;
    top: 8px;
    left: 24px;
    z-index: 1;
    .marker-select {
      width: 100%;
      min-width: 260px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      width: 237px;
    }
  }

  .marker-legends {
    z-index: 1;
    position: absolute;
    left: 24px;
    background: rgba(255, 255, 255, 0.6);
    padding: 5px;
    &.dropdown-visible {
      top: 48px;
      width: 260px;
    }
    &.dropdown-hidden {
      top: 24px;
    }

    h4 {
      font-weight: 600;
    }

    .marker-item {
      cursor: pointer;
    }

    .marker-name {
      font-size: 12px;
    }

    .marker-icon {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      display: block;
    }

    .marker-item-selected {
      font-weight: 600;
    }
  }

  .chart-container {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.leaflet-bottom.leaflet-right {
  width: 100%;

  .leaflet-control-attribution.leaflet-control {
    width: 100%;
    text-align: right;
    padding-bottom: 5px;
    padding-right: 22px;
  }
}

.main-child-table {
  .ant-table-title {
    background: #fff !important;
    border-color: #fafafa;
    border-bottom: 3px solid #0288d1;
    color: #0288d1;
    font-weight: bold;
    padding: 10px;

    &:first-child {
      padding-left: 20px;
    }

    &.ant-table-cell-scrollbar {
      box-shadow: none;
    }
  }
}

.shape-tooltip-container {
  padding: 4px 7px;
  min-width: 225px;
  max-width: 325px;

  h4 {
    font-size: 13px;
    font-weight: bold;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: initial;
  }

  .ant-space-vertical {
    width: 100%;
  }

  .shape-tooltip-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shape-tooltip-name {
      font-size: 12px;
      inline-size: 135px;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: initial;
      min-height: 0;
    }

    .shape-tooltip-value {
      margin-left: 12px;
      font-size: 12px;
      font-weight: bold;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: initial;
    }

    .shape-tooltip-left-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .shape-tooltip-icon {
        width: 12px;
        height: 12px;
        border-radius: 12px;
      }

      .shape-tooltip-name {
        margin-left: 6px;
      }
    }
  }
}

.marker-tooltip-container {
  padding: 4px 7px;
  min-width: 225px;
  max-width: 325px;

  h4 {
    font-size: 13px;
    font-weight: bold;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: initial;
  }

  .marker-tooltip-icon {
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }

  .ant-space-vertical {
    width: 100%;
  }

  .marker-tooltip-wrapper {
    width: 100%;

    .marker-tooltip-title {
      font-size: 12px;
    }

    .marker-tooltip-value {
      font-size: 12px;
      font-weight: bold;
      white-space: pre-wrap; /* CSS3 */
      white-space: -moz-pre-wrap; /* Firefox */
      white-space: -pre-wrap; /* Opera <7 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word;

      &::before {
        content: '\25CF    ';
      }
    }
  }
}

.activity-log .ant-popover-inner-content {
  padding: 0;
  .activity-log-more {
    text-align: center;
    height: 42px;
    margin: 1rem 0;
  }
  .ant-list-items {
    max-height: 19.5rem;
    overflow-y: scroll;
  }
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    font-size: 12px;
    text-transform: capitalize;
  }
  .ant-list-item-meta-title {
    font-weight: bolder;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-list-item {
    min-height: 62px;
  }
  .ant-card-body {
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    font-style: italic;
  }
}
