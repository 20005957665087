.admin-container {
  h1 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .jumbotron-container {
    background: #0288d133 0% 0% no-repeat padding-box;
    height: 150px;

    h1 {
      color: #0288d1;
    }
  }

  .content-wrapper {
    margin-top: -90px;

    .card-container p {
      margin: 0;
    }

    .card-container {
      .ant-tabs-nav-list {
        .tab-pane-text {
          font-size: 18px;
          font-weight: 500;
          padding: 6px 10px;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #3288d0;
          }
        }
      }
    }

    .arfe-container {
      margin-left: -20px;
      margin-right: -20px;

      .ant-card-bordered {
        border: none !important;
      }
    }

    .card-content-container {
      padding: 16px 24px 24px 24px;
      background: #fafafa;
    }

    .card-container > .ant-tabs-card .ant-tabs-content {
      // min-height: 200px;
      margin-top: -16px;
    }

    .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
      padding: 8px;
      background: #fafafa;
    }

    .card-container > .ant-tabs-card > .ant-tabs-nav::before {
      display: none;
    }

    .card-container > .ant-tabs-card .ant-tabs-tab,
    [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
      background: #ededed;
      border-color: #ededed;
      border: 3px solid transparent;
      color: #bfbfbf;
    }

    .card-container > .ant-tabs-card .ant-tabs-tab-active,
    [data-theme='compact']
      .card-container
      > .ant-tabs-card
      .ant-tabs-tab-active {
      background: #fafafa;
      border-color: #fafafa;
      border-bottom: 3px solid #0288d1;
    }

    #components-tabs-demo-card-top .code-box-demo {
      padding: 24px;
      overflow: hidden;
      background: #f5f5f5;
    }

    [data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
      height: 120px;
      margin-top: -8px;
    }

    [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
      background: #ededed;
      border-color: #ededed;
    }

    [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
      background: #000;
    }

    [data-theme='dark']
      .card-container
      > .ant-tabs-card
      .ant-tabs-content
      > .ant-tabs-tabpane {
      background: #141414;
    }

    [data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
      background: #141414;
      border-color: #141414;
    }

    .main-wrapper {
      padding: 20px;
      border-radius: 10px;
      background: #ffff;
      box-shadow: 0 1px 2px 0px #e1e1e1;

      .upload-wrapper {
        margin: 20px 10px;
      }
    }

    .filter-wrapper {
      margin: 12px 0 24px 0;

      .search,
      .filter-select {
        width: 200px;
      }

      .filter-select,
      .remove-filter-button {
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 4px;
      }
    }

    .export-filter-button {
      float: right;
    }

    .checkbox-wrapper {
      margin-bottom: 12px;

      .checkbox-label {
        color: #707070;
        margin-right: -15px;
        font-weight: 600;
      }

      .checkbox-input {
        .ant-checkbox-inner {
          border: 1px solid #70707080;
          border-radius: 4px;
          box-shadow: inset 0px 1px 3px #00000029;
        }
      }
    }

    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .main-child-table {
            .ant-table {
              margin: 0px 0px 0px 32px !important;
              border: 1px solid #eee;
            }

            .editor {
              .ant-input {
                width: 100%;
              }

              .ant-input-number {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .table-wrapper {
      padding-top: 10px;

      .ant-table-thead > tr > th {
        background: #fff !important;
        border-color: #fafafa;
        border-bottom: 3px solid #0288d1;
        color: #0288d1;
        font-weight: bold;
        font-size: 16px;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }

      tr.ant-table-row.edited {
        background-color: #fefebe;
      }

      tr.ant-table-row.edited {
        &:hover {
          td {
            background-color: #ffeb3b;
          }
        }
      }
    }
  }
}

.data-edit-modal {
  .main-child-table {
    .ant-table {
      margin: 0px 0px 0px 0px !important;
      border: 1px solid #eee;

      td {
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }

      .ant-table-expanded-row {
        td {
          padding: 8px;
        }
      }

      .ant-table-expanded-row > .ant-table-cell {
        padding: 20px;
      }
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 0px;
    }
  }

  .ant-table-thead > tr > th {
    background: #fff !important;
    border-color: #fafafa;
    border-bottom: 3px solid #0288d1;
    color: #0288d1;
    font-weight: bold;

    &.ant-table-cell-scrollbar {
      box-shadow: none;
    }
  }
}

.editor {
  .ant-col {
    .ant-input,
    .ant-input-number {
      width: 100%;
    }
  }
}

.loading {
  position: absolute;
  top: 275px;
  z-index: 3;
  background: rgba($color: #fff, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 65%;
  width: 92%;
}

.passcode-wrapper {
  .ant-space-item {
    &:first-child {
      flex: 1;
    }
  }
}

.passcode-item {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  .anticon-info-circle {
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
  }
}
