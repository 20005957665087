.home-container {
  h1 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .jumbotron-wrapper {
    margin-bottom: 24px;
    .jumbotron-overlay-container {
      position: absolute;
      max-width: 60%;
      top: 50px;
      left: 55px;
      z-index: 2;
      background: rgba(255, 255, 255, 0.5);
      padding: 14px 20px 0 20px;
      border-radius: 5px;
    }

    .title,
    .list,
    .jumbotron-text {
      color: #00989f;
      text-shadow: 1px 1px 0px #45add9;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .list {
      font-size: 22px;
    }

    .jumbotron-text {
      font-size: 30px;
      font-weight: bold;
    }

    .map-wrapper {
      z-index: 1;
      height: 100%;
      background: rgba(69, 173, 217, 0.15) 0% 0% no-repeat padding-box;
      border: 1px solid rgba(69, 173, 217, 0.15);
      border-radius: 20px 0px 0px 20px;

      .ant-card-body {
        padding: 0px;
        overflow: hidden;
      }

      .map-container {
        width: 110%;
        margin-left: -5%;
        height: 550px;
      }
    }
  }

  .dataset-container {
    margin-bottom: 24px;
    background: #4194c6 0% 0% no-repeat padding-box;

    h1 {
      color: #fff;
    }

    .dataset-col {
      text-align: center;

      .dataset-item {
        // min-height: 325px;
        min-height: 200px;
        background: #fcfcfc 0% 0% no-repeat padding-box;
        border-radius: 8px;

        h2 {
          font-weight: 600;
        }

        .button-wrapper {
          .read-more {
            margin: 24px 0;
          }

          .explore {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            left: 0;
            right: 0;
            bottom: 24px;
          }

          .read-more,
          .explore {
            color: #45add9;
          }
        }
      }
    }
  }

  .overview-container {
    .container {
      padding-bottom: 0;
    }

    h1 {
      color: #00989f;
    }

    .overview-content-wrapper {
      min-height: 400px;
      padding: 24px 40px;
      background: rgba(2, 136, 209, 0.06);

      .overview-item-col {
        margin-bottom: 16px;
        .overview-icon {
          max-height: 200px;
          object-fit: contain;
        }
      }

      .overview-item-row {
        .overview-item-card {
          border-radius: 8px;
          border: none;
          height: 100%;

          .overview-item {
            min-height: 275px;
          }

          &.water-point {
            color: #45add9;
          }

          &.odf {
            color: #000000;
          }

          &.health-facilities {
            color: #ff9d00;
          }

          &.schools {
            color: #0288d1;
          }

          div {
            &.chart-title {
              padding-bottom: 12px;
              font-size: 16px;
              word-wrap: break-word;
              border-bottom: 1px solid #eeeeee;
            }
            &.area {
              font-size: 20px;
              text-transform: uppercase;
            }

            &.count {
              font-size: 48px;
              font-weight: bold;
            }

            &.text {
              font-size: 24px;
              text-transform: uppercase;
            }

            &.explore {
              color: #45add9;
              margin-top: 30px;
            }
          }
        }
      }

      .ant-card.overview-item-card .ant-card-head-title {
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  .chart-loading {
    .ant-spin-spinning {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: auto;
    }
  }
}
